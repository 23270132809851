.stats {
  flex: 0 1 30%;
  align-self: flex-start;
  font-size: 1.2rem;
  background-color: darken($color: #282c34, $amount: 5);
  border-radius: 1rem;
  padding: 1rem;

  &__title {
    text-align: start;
    margin-top: 0;
    margin-left: 0.4rem;
  }

  .country {
    margin-top: 1rem;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-left: 0.6rem;

    &__name {
      display: flex;
      align-items: center;
      justify-content: stretch;
      line-height: 100%;

      .flag {
        margin-right: 0.4rem;
        border-radius: 0.2rem;
        height: 1.8rem;
      }
    }

    &__population {
      margin-top: 0.6rem;
      font-size: 0.9rem;
    }
  }

  .block {
    margin-top: 1rem;
    padding: 0.6rem;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    font-family: "Open Sans", sans-serif;
    background-color: #282c34;
    border-radius: 0.6rem;

    &__title {
      font-size: 1.5rem;
    }

    &__item {
      font-size: 1rem;

      .value {
        margin-left: 0.5rem;
      }

      &--total {
        font-size: 0.9rem;
        color: darken($color: #ffffff, $amount: 40);
      }
    }
  }
}

@media (max-width: 43em) {
  .stats {
    width: 100%;

    .country {
      align-items: center;
      margin: 1rem 0 0 0;
    }

    .block {
      align-items: center;
    }
  }
}


@media (max-width: 62em) {
  .stats {
    width: 100%;

    .country {
      align-items: center;
      margin: 1rem 0 0 0;
    }

    .block {
      align-items: center;
    }
  }
}
