.worldmap {
  flex: 1 0 70%;
  border: 8px solid darken($color: #282c34, $amount: 5);
  border-radius: 1.2rem;
}

.leaflet-container {
  border-radius: 1rem;
  width: 100%;
  height: 100%;
  min-height: 500px;
}

.flag-icon {
  transition: all 0.25s ease-in-out;
}

@media (max-width: 43rem) {
  .leaflet-container {
    min-height: 300px;
  }
}
