* {
  box-sizing: border-box;
}

.app {
  background-color: #282c34;
  min-height: 100vh;
  text-align: center;
  font-family: "Proza Libre", sans-serif;
  display: flex;
  flex-direction: column;

  .header {
    padding-top: 1rem;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 60px;
    color: white;
    font-size: 2rem;
    font-weight: bold;
  }

  .content {
    margin: auto;
    width: 60%;
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: 2rem;
    color: white;

    &__top {
      margin-top: 2rem;
      display: flex;
      align-items: stretch;
      justify-content: center;
      width: 100%;
      gap: 1rem;
    }

    &__bottom {
      width: 100%;
    }

    @media screen and (max-width: 1200px) {
      width: 80%;
    }
  }

  .footer {
    display: flex;
    align-items: center;
    justify-content: center;
    padding-top: 2rem;
    padding-bottom: 0.5rem;
    height: 100%;
    color: darken($color: white, $amount: 10);

    &__item {
      padding-top: 1rem;
      display: flex;
      align-items: center;
      justify-content: space-evenly;
      min-width: 380px;
      border-top: 1px solid #16191f;
    }

    &__link {
      text-decoration: none;
      padding-left: 1rem;
      color: rgb(243, 92, 92);
    }
  }
}

@media (max-width: 43em) {
  .app {
    .header {
      padding-top: 2rem;
    }

    .content {
      width: 100%;

      &__top {
        flex-direction: column;
      }
    }

    .footer__item {
      flex-direction: column;
      min-width: 250px;
    }

    .footer__link {
      padding-top: 0.3rem;
    }
  }
}
