@import "../../utils/shared.scss";

.global-stats {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  &__title {
    margin-top: 3rem;
    margin-bottom: 1rem;
  }

  &__items {
    display: flex;

    .block {
      margin: 0.5rem;
      padding: 0.8rem;
      background-color: darken($color: #282c34, $amount: 5);
      border-radius: 0.6rem;

      &__title {
        font-size: 1.1rem;
      }

      &__item {
        padding-top: 0.4rem;
        font-size: 2rem;
        display: flex;
        justify-content: space-between;

        .value {
          margin-left: 0.5rem;
        }

        .percentage {
          margin-left: 0.5rem;
        }

        &--total {
          font-size: 0.9rem;
          color: darken($color: #ffffff, $amount: 40);
        }
      }
    }
  }
}

@media (max-width: 43em) {
  .global-stats__items {
    flex-direction: column;
  }
}
